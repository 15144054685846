const config = {
  site: {
    title: 'Opus 11',
    description:
      'Camerata Opus 11™ es una institución especializada en servicios culturales y en la creación de espectáculos de música sinfónica, con un alto concepto de excelencia artística.\n' +
      'Colaboramos con importantes empresas, a través de la creación de espectáculos enfocados a la recreación y al entretenimiento, a fin de contribuir al desarrollo humano, integración laboral, desarrollo organizacional, comunicación, cultura corporativa, imagen y/o publicidad de la misma.',
  },
  googleTagManager: {
    id: process.env.GOOGLE_TAG_MANAGER_ID,
  },
  googleMaps: {
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '',
  },
  googleReCaptcha: {
    siteKey: process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY ?? '',
  },
  stripe: {
    publishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ?? '',
    account: process.env.NEXT_PUBLIC_STRIPE_ACCOUNT ?? '',
  },
  newrelic: {
    applicationId: process.env.NEWRELIC_APPLICATION_ID,
  },
  api: {
    baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
  },
  contentful: {
    space: process.env.CONTENTFUL_SPACE ?? '',
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN ?? '',
    environment: process.env.CONTENTFUL_ENVIRONMENT ?? 'master',
  },
};

export default config;
