import { createAsyncThunk } from '@reduxjs/toolkit';
import APIError from '../models/APIError';
import { api } from '../services';
import Reservation from '../models/Reservation';

export const createReservation = createAsyncThunk<
  { reservation: Reservation },
  {
    concert: string;
    seats: string[];
    coupon?: string;
    name: string;
    phone: string;
    email: string;
    reCaptchaToken: string;
    stripeToken: string;
  },
  { rejectValue: APIError }
>('reservations/create', async (payload, { rejectWithValue }) => {
  const result = await api.post<Reservation>('/reservations', payload);

  if (result.error) {
    return rejectWithValue(result.error);
  }

  return {
    reservation: result.resource,
  };
});
