import { colors, createTheme } from '@mui/material';

const base = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffd800',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#141419',
      secondary: colors.grey[500],
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    h1: {
      fontSize: 24,
      fontWeight: 700,
      [base.breakpoints.up('md')]: {
        fontSize: 32,
      },
    },
    h2: {
      fontSize: 24,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.5,
    },
    button: {
      textTransform: 'none',
    },
    caption: {
      lineHeight: 1,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: '#141419',
        fontWeight: 500,
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        html {
          scroll-behavior: smooth; 
        }

        #hubspot-messages-iframe-container.widget-align-right {
          bottom: 64px !important;
        }

        .grecaptcha-badge {
          display: none !important; 
        }

        @media (min-width: 900px) {
          #hubspot-messages-iframe-container.widget-align-right {
            bottom: 0 !important;
          }
        }
      `,
    },
  },
});

export default theme;
