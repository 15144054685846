import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';
import { closeDrawer, openDrawer } from '../slices/drawer';

export default function useDrawer() {
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) => state.drawer.open);

  const onOpen = () => dispatch(openDrawer());

  const onClose = () => dispatch(closeDrawer());

  return {
    open,
    onOpen,
    onClose,
  };
}
