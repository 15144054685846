import React from 'react';
import { Box, Container, Divider, Grid, Link, Typography } from '@mui/material';
import Image from 'next/image';
import NextLink from 'next/link';

const Footer: React.FC = () => {
  return (
    <footer>
      <Divider sx={{ borderColor: 'black', marginTop: 4 }} />

      <Container maxWidth="lg" sx={{ pt: 4, pb: 2 }}>
        <Grid container columnSpacing={4} rowSpacing={{ xs: 4, md: 0 }}>
          <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Box sx={{ mb: 1 }}>
              <Image src="/isotype.svg" width={60} height={68} alt="Logo de Camerata Opus 11" unoptimized />
            </Box>

            <Typography>
              <em>Música que Transforma y Trasciende Vidas</em>
            </Typography>
          </Grid>
          <Grid item xs={6} md="auto">
            <Box component="ul" sx={{ listStyle: 'none', m: 0, p: 0 }}>
              {[
                {
                  href: '/quienes-somos',
                  label: '¿Quiénes somos?',
                },
                {
                  href: '/preguntas-frecuentes',
                  label: 'Preguntas frecuentes',
                },
                {
                  href: '/descuentos-corporativos',
                  label: 'Descuentos corporativos',
                },
                {
                  href: '/responsabilidad-social',
                  label: 'Responsabilidad social',
                },
                {
                  href: '/audiciones',
                  label: 'Audiciones',
                },
              ].map(({ href, label }, index) => (
                <Box component="li" key={index} sx={{ mb: 1.5, lineHeight: 1.2 }}>
                  <NextLink href={href} passHref>
                    <Link underline="hover" sx={{ fontWeight: '400' }}>
                      {label}
                    </Link>
                  </NextLink>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={6} md="auto">
            <Box component="ul" sx={{ listStyle: 'none', m: 0, p: 0 }}>
              {[
                { href: 'https://www.facebook.com/camerataopus11/', label: 'Facebook' },
                { href: 'https://www.instagram.com/camerataopus11/', label: 'Instagram' },
                { href: 'https://twitter.com/camerataopus11', label: 'Twitter' },
                { href: 'https://www.youtube.com/channel/UCpMA8Bz3S-biGvWuxIt_E-Q/about', label: 'YouTube' },
                { href: 'https://www.linkedin.com/company/camerataopus11/', label: 'LinkedIn' },
              ].map(({ href, label }, index) => (
                <Box component="li" key={index} sx={{ mb: 1.5, lineHeight: 1.2 }}>
                  <Link
                    href={href}
                    underline="hover"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ fontWeight: '400' }}
                  >
                    {label}
                  </Link>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={2} sx={{ mt: 2 }} justifyContent={{ xs: 'center', md: 'space-between' }}>
          <Grid item md>
            <Typography variant="caption">© 2022, Camerata Opus 11</Typography>
          </Grid>
          <Grid item container columnSpacing={2} md justifyContent={{ xs: 'center', md: 'flex-end' }}>
            {[
              { href: '/aviso-de-privacidad/', label: 'Aviso de privacidad' },
              { href: '/terminos-y-condiciones/', label: 'Términos y condiciones' },
            ].map(({ href, label }, index) => (
              <Grid item key={index}>
                <NextLink href={href} passHref>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="caption"
                    underline="hover"
                    sx={{ fontWeight: 400 }}
                  >
                    {label}
                  </Link>
                </NextLink>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
