import { createAsyncThunk } from '@reduxjs/toolkit';
import APIError from '../models/APIError';
import { api } from '../services';
import { AppState } from '../store';
import Price from '../models/Price';
import Contact from '../models/Contact';

export const calculatePrice = createAsyncThunk<
  { price: Price },
  { concert: string },
  { state: AppState; rejectValue: APIError }
>('checkout/calculatePrice', async (payload, { getState, rejectWithValue }) => {
  const checkout = getState().checkout;
  const result = await api.post<Price>('/calculator', {
    ...payload,
    seats: checkout.seats.map((seat) => seat.id),
    coupon: checkout.coupon,
  });

  if (result.error) {
    return rejectWithValue(result.error);
  }

  return {
    price: result.resource,
  };
});

export const updateContact = createAsyncThunk<{ contact: Contact }, Contact, { rejectValue: APIError }>(
  'checkout/updateContact',
  async (payload, { rejectWithValue }) => {
    const result = await api.post<Contact>('/contacts', payload);

    if (result.error) {
      return rejectWithValue(result.error);
    }

    return {
      contact: result.resource,
    };
  }
);

export const updateCoupon = createAsyncThunk<
  { coupon: string; price: Price },
  { concert: string; coupon: string },
  { state: AppState; rejectValue: APIError }
>('checkout/updateCoupon', async (payload, { getState, rejectWithValue }) => {
  const checkout = getState().checkout;
  const result = await api.post<Price>(`/calculator`, { ...payload, seats: checkout.seats.map((seat) => seat.id) });

  if (result.error) {
    return rejectWithValue(result.error);
  }

  return {
    coupon: payload.coupon,
    price: result.resource,
  };
});
