import type { AppProps } from 'next/app';
import { Container, CssBaseline, ThemeProvider } from '@mui/material';
import theme from '../theme';
import { wrapper } from '../store';
import Head from 'next/head';
import config from '../config';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MobileDrawer from '../components/MobileDrawer';
import { SWRConfig } from 'swr';
import fetcher from '../fetcher';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const track = (url: string) =>
  // @ts-ignore
  window.dataLayer?.push({
    event: 'pageview',
    page: url,
  });

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', track);

    return () => router.events.off('routeChangeComplete', track);
  }, [router.events]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Head>
        <title>{config.site.title}</title>
        <meta key="description" name="description" content={config.site.description} />
      </Head>

      <Header />

      <MobileDrawer />

      <SWRConfig value={{ fetcher }}>
        <Container maxWidth="lg" component="main">
          <Component {...pageProps} />
        </Container>
      </SWRConfig>

      <Footer />
    </ThemeProvider>
  );
}

export default wrapper.withRedux(MyApp);
