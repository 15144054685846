import React from 'react';
import { Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar } from '@mui/material';
import Link from 'next/link';
import { menu } from '../constants/menu';
import { X } from 'react-feather';
import useDrawer from '../hooks/useDrawer';

const MobileDrawer: React.FC = () => {
  const { open, onClose } = useDrawer();

  return (
    <Drawer open={open} anchor="left" elevation={0} onClose={onClose}>
      <Toolbar disableGutters>
        <IconButton onClick={onClose}>
          <X />
        </IconButton>
      </Toolbar>

      <List sx={{ width: 250 }}>
        {menu.map(({ href, label }, index) => (
          <ListItem key={index} disableGutters disablePadding>
            <Link href={href} passHref>
              <ListItemButton>
                <ListItemText primary={label} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default MobileDrawer;
