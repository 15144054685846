import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
} as { open: boolean };

const slice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer: (state) => {
      state.open = true;
    },
    closeDrawer: (state) => {
      state.open = false;
    },
  },
});

export const { openDrawer, closeDrawer } = slice.actions;

export default slice.reducer;
