import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createReservation } from '../thunks/reservations';
import { calculatePrice, updateContact, updateCoupon } from '../thunks/checkout';
import CheckoutState from '../models/CheckoutState';
import Seat from '../models/Seat';

const initialState = {
  seats: [],
} as CheckoutState;

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    updateSeats: (state, action: PayloadAction<{ seats: Seat[] }>) => {
      state.seats = action.payload.seats;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(calculatePrice.fulfilled, (state, { payload }) => {
      state.price = payload.price;
    });

    builder.addCase(updateCoupon.fulfilled, (state, { payload }) => {
      state.coupon = payload.coupon;
      state.price = payload.price;
    });

    builder.addCase(updateContact.fulfilled, (state, { payload }) => {
      state.contact = payload.contact;
    });

    builder.addCase(createReservation.fulfilled, (state) => {
      state.seats = [];
    });
  },
});

export const { updateSeats } = slice.actions;

export default slice.reducer;
