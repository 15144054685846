import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import config from '../config';
import APIError from '../models/APIError';

export type APIResult<T> = { error?: undefined; resource: T } | { error: APIError; resource?: undefined };

export default class APIService {
  http: AxiosInstance;

  constructor() {
    this.http = axios.create({
      baseURL: config.api.baseUrl,
    });
  }

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<APIResult<T>> {
    try {
      const response = await this.http.get<T>(url, config);

      return {
        resource: response.data,
      };
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;

        return {
          error,
        };
      }

      throw err;
    }
  }

  async post<T>(url: string, data: unknown): Promise<APIResult<T>> {
    try {
      const response = await this.http.post<T>(url, data);

      return {
        resource: response.data,
      };
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;

        return {
          error,
        };
      }

      throw err;
    }
  }

  async patch<T>(url: string, data: unknown): Promise<APIResult<T>> {
    try {
      const response = await this.http.patch<T>(url, data);

      return {
        resource: response.data,
      };
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;

        return {
          error,
        };
      }

      throw err;
    }
  }

  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<APIResult<T>> {
    try {
      const response = await this.http.delete<T>(url, config);

      return {
        resource: response.data,
      };
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;

        return {
          error,
        };
      }

      throw err;
    }
  }
}
