import React from 'react';
import Image from 'next/image';
import { AppBar, Box, Button, Container, IconButton, Link, Toolbar } from '@mui/material';
import NextLink from 'next/link';
import { Menu } from 'react-feather';
import { menu } from '../constants/menu';
import useDrawer from '../hooks/useDrawer';

const Header: React.FC = () => {
  const { onOpen } = useDrawer();

  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', mb: 4 }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <IconButton color="inherit" sx={{ display: { md: 'none' }, mr: 1 }} onClick={onOpen}>
            <Menu />
          </IconButton>

          <NextLink href="/" passHref>
            <Link sx={{ mt: '4px' }}>
              <Image src="/logo.svg" alt="Camerata Opus 11" width={66} height={52} unoptimized />
            </Link>
          </NextLink>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, flex: 1, gap: 4, ml: 4 }}>
            {menu.map(({ href, label }, index) => (
              <NextLink key={index} href={href} passHref>
                <Link underline="hover" sx={{ fontWeight: 400 }}>
                  {label}
                </Link>
              </NextLink>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
